.services-page {
  margin-top: 1px;
  background-color: var(--bg-week);
}

.service-welcome {
  margin: 0px 24px;
  padding: 208px 24px; 
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-greet {
  color: var(--text-sub, #475467);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  width: 192px;
  height: 40px;
  padding: 8px;
  border-radius: 24px;
  background: var(--bg-soft, #eaecf0);
}

.service-tagline {
  color: var(--text-strong, #0c111d);
  font-size: 52px;
  font-weight: 500;
  line-height: 64px;
  padding: 21px 0px;
}

.service-tagline span:nth-child(1) {
  color: #63c5ea;
}

.service-tagline span:nth-child(2) {
  color: #ea4b8b;
}

.service-greet-text {
  color: var(--text-soft, #98a2b3);
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.forth-template,
.forth-template-rev {
  display: flex;
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
}
.forth-template {
  margin-left: 28px;
}

.component-image {
  flex: 1 0 auto;
  background-color: var(--bg-white);
  padding: 2rem 0rem 2rem 2rem;
  border: 1px solid var(--storke-soft);
  border-right: none;
  border-radius: 40px 0px 0px 40px;
  width: 100%; 
  height: 100%;
}

.service-section {
  flex: auto;
  background-color: var(--bg-white);
  align-self: stretch;
  border: 1px solid var(--storke-soft, #eaecf0);
  border-radius: 40px;
}

.image-conatiner {
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  height: auto;
  flex:1 auto ;
  display: block;
  overflow: hidden;
  position: relative;
}

.section-card-container {
  display: flex;
  gap: 1.75rem;
  padding: 0rem 2rem;
  justify-content: center;
}

.section-card-3 {
  background: var(--bg-white, #fff);
  border: 1px solid var(--storke-soft, #eaecf0);
  border-radius: 40px;
  margin: 0px 28px;
  padding: 36px 84px;
  flex: 1 0 calc(50% - 2rem); 
}

.forth-template-rev {
  margin-right: 28px;
  position: relative;
  
}
.rev-component-image {
  min-width: 480px;
  background-color: var(--bg-white);
  padding: 2rem 2rem 2rem 0rem;
  border: 1px solid var(--storke-soft);
  border-right: none;
  border-radius: 0px 40px 40px 0px;
  height: 100%;

}

@media (max-width: 1024px) {
  .service-welcome {
    padding: 180px 24px; 
  }

  .service-tagline {
    font-size: 42px;
    line-height: 48px;
  }

  .service-greet-text {
    font-size: 20px;
    line-height: 28px;
  }

  .section-card-3 {
    flex: 1 0 100%; 
    padding: 20px 24px;
    margin: 0px 28px; 
  }

  .rev-component-image {
    min-width: 100%;
    height: 100%;
  }
}

@media (max-width: 768px) {

  .service-greet {
    font-size: 14px;
  }

  .service-tagline {
    font-size: 32px;
    line-height: 40px;
  }

  .service-greet-text {
    font-size: 18px;
    line-height: 24px;
  }
  .section-card-container ,
  .forth-template, .forth-template-rev  {
    flex-direction: column; 
  }
  .forth-template-rev .service-section {
    margin-left: 28px;
  }
  .forth-template .service-section {
    margin-right: 28px;
  }
}

@media (max-width: 480px) {
  .service-welcome {
    padding:86px 28px 40px;
  }

  .service-tagline {
    font-size: 24px;
    line-height: 36px;
  }

  .service-greet-text {
    font-size: 16px;
    line-height: 22px;
  }

  .component-image {
    height: 250px; /* Smaller image size for mobile */
  }
}