 .contactUs-page {
  margin-top: 1px;
}

@media (max-width: 768px) {
    .modal-content {
        width: 100%;
    }
}

.contactUs {
    padding: 148px 108px 96px;
    display: flex;
    justify-content: space-between;
    background: var(--bg-week, #f9fafb);
    align-items: center;
    flex-wrap: wrap; 
  }

.contactUs-form, .contactUs-header {
    flex: 1 1 50%;
    margin: 20px; 
  }

.contacts-head,
.contactUs-head {
  font-size: 56px;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  color: var(--text-strong, #0c111d);
}

.contacts-subhead,
.contactUs-subhead {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: var(--text-soft, #98a2b3);
}

.contact-cross {
  display: none;
}

.contacts {
  padding: 0px 108px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; 
}

.contacts-buttons {
  display: flex;
  flex-wrap: wrap;
}

.contacts-button {
  margin: 8px;
  padding: 16px 32px;
  text-align: center;
  border-radius: 40px;
  border: 1px solid var(--storke-soft, #eaecf0);
  background: var(--bg-white, #fff);
}

.contacts-header {
    width: 30%;
  padding: 40px 0px;
}

  
  @media (max-width: 768px) {
    .contactUs {
      flex-direction: column;
      padding: 80px 40px;
    }
  
    .contactUs-form,
    .contactUs-header {
      flex: 1 1 100%;
    }
  
    .contacts {
      padding: 0 20px;
      flex-direction: column;
    }
  
    .contacts-header {
      width: 100%;
    }
  
    .contacts-head {
      font-size: 32px; 
      line-height: 40px;
    }
  
    .contacts-subhead {
      width: 100%;
      font-size: 18px;
      line-height: 28px;
      padding: 10px 0;
    }
  
    .contacts-buttons {
      justify-content: center;
    }
  
    .contacts-button {
      width: 100%;
      padding: 12px;
    }
  
    .contacts-buttons {
      flex-direction: column;
    }
  
    .sendButton {
      width: 100%;
      padding: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .contacts-head {
      font-size: 24px; /* Further reduce font size on very small screens */
      line-height: 32px;
    }
  
    .contacts-subhead {
      font-size: 16px;
      line-height: 24px;
    }
  
    .contactUs-header {
      padding: 20px 0;
    }
  
    .contacts-buttons {
      flex-direction: column;
      width: 100%;
    }
  
    .contacts-button {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .modal-content {
      padding: 20px 10px;
    }
  }
  