*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*::selection {
  background: var(--main-color);
  color: var(--bg-color);
}

html {
  font-size: 16px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

@media (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 10px;
  }
}

body {
  font-family: "Archivo", sans-serif;
  background-color: var(--bg-color);
  transition: background-color 0.3s, color 0.3s;
  color: var(--text-color);
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#darkMode-icon {
  font-size: 1.5rem;
  color: var(--static-white);
  cursor: pointer;
  text-decoration: none;
  border: none;
  background-color: #7d52f4;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

#darkMode-icon:hover {
  color: #55cee6;
}

:root {
  --main-color: #55cee6;
  --primary-light: #cac0ff;
  --primary-darker: #7d52f4;

  --text-color: #000000;
  --text-soft: #98a2b3;
  --text-strong: #0c111d;
  --bg-color: #fdfdfd;
  --bg-week: #f9fafb;
  --bg-white: #fff;
  --static-white: #fff;
  --link-color: rgb(88, 88, 88);
  --bgc-icon: #fff;
  --icon-strong: #0c111d;
  --popup-container-background-color: rgba(0, 0, 0, 0.65);
  --swiper-navigation-size: 24px;
}

body.light {
  --storke-soft: #eaecf0;
  --text-color: #000;
  --bg-color: #fdfdfd;
  --bgc-icon: #fff;
  --text-sub: #475467;
  --text-strong: #0c111d;
  --text-soft: #98a2b3;
  --bg-week: #f9fafb;
  --link-color: rgb(88, 88, 88);
  --bg-white: #fff;
}

body.dark {
  --storke-soft: #333741;
  --primary-light: rgba(120, 77, 239, 0.24);
  --bg-color: #0c111d;
  --text-sub: #94969c;
  --text-color: #fdfdfd;
  --text-strong: #fff;
  --bg-week: #161b26;
  --bg-white: #0c111d;
  --text-soft: #85888e;
  --bgc-icon: #cac0ff;
  --icon-strong: #fff;
}

.scroll-reveal {
  opacity: 0.3;
  transition: opacity 0.5s ease-in-out;
}

.scroll-reveal.is-visible {
  opacity: 1;
}

.button {
  padding: 0.75rem 1.5rem;
  background: #7d52f4;
  border-radius: 2rem;
  color: white;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #5a3bc1;
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.1);
}

.Home {
  margin: 1px;
}

.navbar {
  position: fixed;
  width: 100%;
  z-index: 1000;
}
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4.5rem;
  max-width: 1016px;
  margin: 1.5rem auto;
  padding: 1.5rem 1rem;
  border: 1px solid var(--storke-soft, #eaecf0);
  border-radius: 2.5rem;
  background: #ffffff1a;
  -webkit-backdrop-filter: blur(8%);
  backdrop-filter: blur(8px);
}

.nav-logo {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.logo-icon {
  height: 5.875rem;
  width: 5.875rem;
}
/* ++++++++++++++++++++++ */
.nav {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.menu {
  height: 56px;
  width: 56px;
  display: none;
  cursor: pointer;
}

.menu-button {
  background: none;
  border: none;
  cursor: pointer;
}

/* Menu List */
.menu-list {
  position: absolute;
  top: 85px;
  right: 5%;
  width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  -webkit-backdrop-filter: blur(8%);
  background: #ffffff1a;
  backdrop-filter: blur(8px);
  border-radius: 2.5rem;
  border: 1px solid var(--storke-soft, #eaecf0);
}

.menu-item {
  text-decoration: none;
  font-size: 1.2rem;
  color: var(--text-color);
  width: 100%;
  text-align: center;
  padding: 0.5rem 0;
  background: #f0f0f090;
  border-radius: 24px;
  transition: background 0.3s, color 0.3s;
}

.menu-item:hover {
  background: #f0f0f0;
  color: #7d52f4;
}

.menu-quote-button {
  background: #7d52f4;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 24px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  text-align: center;
}
/* =================================== */
.navword {
  color: var(--text-strong);
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: 500;
  text-align: center;
  transition: color 0.3s, border-bottom 0.3s;
}

.navword:hover,
.navword.active {
  border-radius: 0.125rem;
  border-bottom: 0.125rem solid var(--primary-darker);
  color: var(--primary-darker);
}

.quote-button {
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  background: var(--primary-darker);
  color: var(--static-white);
  border: 1px solid var(--primary-light);
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.background-container {
  position: absolute;
  width: 100%;
  overflow: hidden;
  z-index: -1;
  -webkit-backdrop-filter: blur(8%);
  backdrop-filter: blur(8px);
}

.app-header {
  position: relative;
  width: 100%;
  background-color: transparent;
  font-size: 2rem;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9.25rem 6.75rem 6rem;
  gap: 1.3125rem;
  overflow: hidden;
}

.app-header h1 {
  font-size: 5.25rem;
  font-weight: 700;
  text-align: center;
  color: var(--text-strong);
}

.app-header div span:nth-child(2) {
  color: #ea4b8b;
}

.app-header div span:nth-child(4) {
  color: #63c5ea;
}

.app-header p {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--text-sub, #475467);
  text-align: center;
}

.app-header button {
  border: 1px solid var(--primary-light);
  background: var(--primary-darker);
  color: var(--static-white);
  border-radius: 2rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.about-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  margin: 0 6rem 6.75rem;
}

.about-content {
  width: 100%;
  max-width: 30.5rem;
  align-self: flex-start;
}

.about-heading {
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 4rem;
  color: var(--text-strong, #fff);
}

.about-text {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  color: var(--text-sub, #94969c);
  text-align: justify;
}

.about-text p:first-child {
  color: #7d52f4;
  font-weight: 500;
  padding-bottom: 0.5rem;
}
@supports (aspect-ratio: 1) {
.about-image {
  width: 100%;
  max-width: 31rem;
  height: auto;
  aspect-ratio: 1;
  border-radius: 1.5rem;
  border: 0.75rem solid #63c5ea;
  background: var(--bg-white, #0c111d);
  box-shadow: 0.125rem 0.125rem 0 0 #000, 1rem 1rem 0 0 #ea4b8b;
  object-fit: cover;
}
}
.section-container {
  width: 100%;
  margin-bottom: 6rem;
}

.section-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  margin: 1rem;
}

.section-heading {
  font-size: 3.5rem;
  font-weight: 700;
  color: var(--text-strong, #0c111d);
}

.section-heading span svg {
  display: inline;
  height: 4rem;
  width: 2.67rem;
}

.section-sub-heading {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 500;
  color: var(--text-soft, #98a2b3);
}

.card-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(20rem, 1fr));
  grid-template-rows: repeat(2, 1fr);
  justify-content: center;
  gap: 1.75rem;
  margin: 2rem 6rem;
}

.section-card {
  width: 100%;
  border: 1px solid var(--storke-soft, #eaecf0);
  background: var(--bg-week, #f9fafb);
  border-radius: 0.75rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 0.5rem;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 8px;
  stroke: var(--icon-strong, #0c111d);
  stroke-width: 0.0625rem;
}

.card-text {
  width: 100%;
  max-width: 18rem;
}

.card-text h3 {
  color: var(--text-strong, #0c111d);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: left;
}

.card-text p {
  color: var(--text-soft, #98a2b3);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  text-align: left;
}

.services {
 padding: 0 6.75rem 0rem;
}

.services-header {
  margin-bottom: 3rem;
}

.services-header h2 {
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 4rem;
  color: var(--text-strong, #0c111d);
  text-align: left;
  margin-bottom: 1rem;
}

.services-header p {
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  text-align: left;
  color: var(--text-soft, #98a2b3);
  width: 100%;
  max-width: 50.5rem;
  margin-bottom: 0.75rem;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;
  max-width: 75rem;
}

.services-card {
  border: 1px solid var(--storke-soft, #eaecf0);
  border-radius: 0.75rem;
  padding: 2rem;
  background-color: var(--bg-week, #f9fafb);
}

.services-card-icon {
  width: 4rem;
  height: 4rem;
  background-color: var(--bgc-icon, #fff);
  border-radius: 0.75rem;
  margin-bottom: 1rem;
}

.services-card h3 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  color: var(--text-strong, #0c111d);
  margin-bottom: 0.75rem;
  text-align: left;
}

.services-card-text ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.services-card-text ul li {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: var(--text-soft, #98a2b3);
  text-align: left;
  margin-bottom: 0.5rem;
}

/* ------------------------------------------------------------------------------------- */
/* Base Styles */
.portfolio {
  width: 100%;
  overflow-x: hidden;
}

.portfolio-container {
  width: 100%;
  padding: 0 6.75rem;
  gap: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  margin-top: 3rem;
  flex-wrap: nowrap;
}

.portfolio-header {
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 4rem;
  text-align: left;
  color: var(--text-strong, #0c111d);
  margin-bottom: 1.5rem;
}

.portfolio-header span {
  color: var(--primary-darker, #7d52f4);
}

.portfolio-btn-container {
  display: flex;
  gap: 1.5rem;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 1rem;
}

.portfolio-btn-container button {
  width: 18rem;
  padding: 1rem 2rem;
  border-radius: 2rem;
  border: 1px solid var(--stroke-sub, #d0d5dd);
  background: var(--bg-white, #fff);
  color: var(--text-strong, #0c111d);
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.portfolio-btn-container button:nth-child(2) {
  border: 1px solid var(--primary-light, #cac0ff);
  background: var(--primary-darker, #7d52f4);
  color: var(--static-white, #fff);
}

.portfolio-card-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
  flex-wrap: nowrap;
  margin-bottom: 3rem;
  animation: scrollCards 20s linear infinite;
  padding-right: 1.5rem;
}
.portfolio-card-container::-webkit-scrollbar {
  display: none;
}

@keyframes scrollCards {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
.paused {
  animation-play-state: paused;
  animation: none;
  overflow-x: auto;
}

.portfolio-card {
  width: 15.8125rem;
  height: 25.5rem;
  border-radius: 4rem;
  background: var(--bg-soft, #eaecf0);
  flex-shrink: 0;
  border: 1px solid #ccc;
  overflow: hidden;
  transition: transform 0.3s;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.portfolio-card:hover {
  width: 21.875rem;
  transition: width 0.3s ease;
}

.portfolio-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.card-details {
  position: absolute;
  width: 80%;
  bottom: 24px;
  padding: 16px 24px;
  border-radius: 2.5rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s ease, visibility 0.5s ease;
  background: var(--static-white, #fff);
}

.card-link {
  position: absolute;
  top: 24px;
  right: 24px;
  border-radius: 40px;
  padding: 16px;
  background: var(--static-white, #fff);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s ease, visibility 0.5s ease;
}
.portfolio-card:hover .card-details,
.portfolio-card:hover .card-link {
  visibility: visible;
  opacity: 1;
}
.card-link:hover {
  background-color: #d9d9d9;
}
.card-heading {
  color: #0c111d;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.card-about {
  color: #98a2b3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
}

/* ---------------------------------------- */
.swiperArrow {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 15.625rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.swiper-button-prev,
.swiper-button-next {
  --swiper-navigation-size: 1.5rem;
  border: 1px solid #007aff;
  padding: 1.2rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.swiper-button-prev {
  left: -1rem;
}

.swiper-button-next {
  left: 1rem;
}

.dot-container {
  width: 100%;
}

/* ------------------------------------------------------------------- */
.testimonial-header {
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 4rem;
  text-align: left;
  margin-bottom: 3rem;
}

.testimonial-header span {
  color: var(--primary-darker, #7d52f4);
}

.testimonial-container {
  margin: 3rem 6.75rem;
  z-index: -1;
  height: 100%;
}

.testimonial-card {
  background-color: var(--bg-color);
  border-radius: 1rem;
  padding: 1.5rem;
}

.testimonial-card-content {
  display: flex;
  gap: 1.5rem;
}

.testimonial-card-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.testimonial-text-head {
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  color: var(--text-strong, #0c111d);
  text-align: left;
}

.testimonial-text-para {
  width: 90%;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--text-sub, #475467);
  text-align: justify;
}

.testimonial-detail {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
}

.testimonial-details-image {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #475467;
  background-size: cover;
}

.testimonial-details-name h4 {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: var(--text-strong, #0c111d);
}

.testimonial-details-name h5 {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: var(--text-sub, #475467);
}

.vertical-rule {
  border-right: 1px solid var(--icon-strong);
  margin: 0 3rem;
}

.middle-rule {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: -1;
}

.rule-div {
  width: 50%;
  height: 90%;
}

.rule {
  border-right: 1px solid var(--icon-strong);
}

/* ============================================================= */
.faq {
  display: flex;
  flex-wrap: nowrap;
  margin: 6rem 6.75rem;
  justify-content: space-between;
  gap: 2rem;
}

.faq-head {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 100%;
}

.faq-heading {
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 4rem;
  text-align: left;
  color: var(--text-strong, #0c111d);
}

.faq-text {
  width: 100%;
  max-width: 18rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  text-align: left;
  color: var(--text-soft, #98a2b3);
}

.faq-button {
  margin-top: 1rem;
  padding: 1rem 2rem;
  width: 100%;
  max-width: 18rem;
  border-radius: 2rem;
  border: 1px solid var(--primary-light, #cac0ff);
  background: var(--primary-darker, #7d52f4);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-button:hover {
  background-color: #6c4dd3;
}

.faq-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 40rem;
  overflow-y: auto;
}

.faq-content::-webkit-scrollbar {
  display: none;
}

.faq-card {
  width: 100%;
  max-width: 50rem;
  padding: 2rem 3rem;
  border-radius: 1.5rem;
  border: 2px solid var(--stroke-sub, #d0d5dd);
  background: var(--bg-white, #fff);
  transition: box-shadow 0.3s ease;
}

.faq-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.faq-slide {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.faq-card-text {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  text-align: left;
  color: var(--text-strong, #0c111d);
}

.faq-card-icon {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 2rem;
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.faq-query p {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  margin-top: 1rem;
  color: var(--text-sub, #475467);
}

.faq-open {
  padding: 0.5rem 0;
}

/* --------------------------------- */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0; 
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow: hidden;
}

.modal-content {
  width: 100%;
  height: auto;
  max-width: 600px;
  padding: 40px;
  border-radius: 32px;
  background: var(--bg-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
}

.modal-content::-webkit-scrollbar {
  width: 3px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: var(--stroke-sub);
  border-radius: 10px;
}

.modal-content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-content h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: var(--text-strong);
}

.modal-content label {
  font-size: 16px;
  color: var(--text-strong);
}

.modal-content input,
.modal-content textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid var(--stroke-sub, #d0d5dd);
  border-radius: 8px;
  background: var(--bg-white);
  font-size: 16px;
  box-shadow: 0px 1px 2px rgba(27, 28, 29, 0.03);
}

.modal-content input:focus,
.modal-content textarea:focus {
  border-color: var(--primary-darker);
  outline: none;
}

.modal-content textarea {
  min-height: 148px;
  resize: vertical;
}

.sendButton {
  padding: 12px 24px;
  border-radius: 36px;
  background-color: var(--primary-darker);
  color: #fff;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 100%;
}

.sendButton:hover {
  background-color: var(--primary-darker);
  transform: scale(1.05);
}

.sendButton:active {
  transform: scale(0.98);
}
.success-message{
  color: rgb(28, 229, 28);
  text-align: center;
}

.full-name {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
}

.name {
  flex: 1;
  min-width: 150px;
}

.no-scroll {
  overflow: hidden; 
}

/* ========================================================= */

.footer {
  margin: 0px 28px 0;
  border-radius: 64px 64px 0 0;
  background-color: #7d52f4;
  padding: 64px 56px 12px;
  color: #ffffff;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
}

.footer-head-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 72px;
}

.footer-heading {
  font-family: "Archivo", sans-serif;
  font-size: 3rem;
  font-weight: 500;
  line-height: 56px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 16px;
}

.footer-head-text {
  font-family: "Archivo", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #ffffff;
}

.footer-button {
  width: 288px;
  padding: 16px 32px;
  border-radius: 32px;
  border: 1px solid #fff;
  color: #fff;
  background: #7d52f4;
  box-shadow: 0px 1px 2px rgba(27, 28, 29, 0.03);
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease-in-out;
  margin-top: 16px;
}

.footer-button:hover {
  background-color: #6a3dcb;
  transform: scale(1.05);
}

.footer-button:active {
  transform: scale(0.98);
}

.footer-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 18px;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 72px;
}

.flogo-icon {
  height: 40px;
  width: 40px;
}

.flogo-text {
  font-size: 3.5rem;
  line-height: 2rem;
  font-weight: 500;
  color: #fff;
}

.footer-text {
  margin: 12px 0px 24px;
}

.footer-text p {
  width: 396px;
  font-family: "Archivo", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
}

.footer-text p:nth-child(2) {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
}

.copyright-tag {
  font-family: "Archivo", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  color: #fff;
}

.footer-options {
  margin: 24px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 16px;
}

.footer-links,
.footer-policies {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}

.footer-links a,
.footer-policies a {
  font-size: 1.125rem;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover,
.footer-policies a:hover {
  color: #cac0ff;
}

.footer-socialmedia {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.footer-socialmedia a {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  padding: 8px 0px 8px 16px;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-socialmedia a:hover {
  color: #cac0ff;
}

.foot-last {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .nav-container {
    padding: 16px;
    width: 90%;
  }

  .navword {
    font-size: 14px;
  }

  .quote-button {
    font-size: 14px;
  }

  .logo-icon {
    width: 80px;
    height: 80px;
  }

  .app-header h1 {
    font-size: 64px;
  }

  .app-header p {
    font-size: 18px;
  }

  .app-header button {
    padding: 12px 24px;
    font-size: 14px;
  }
  .swiperArrow {
    height: 4.2rem;
    width: 16.625rem;
    left: 50%;
  }
  .footer-head-container {
    margin-bottom: 72px;
  }
  .footer-body {
    margin-top: 10px;
  }
}

@media (max-width: 1024px) {
  .app-header {
    padding: 124px 54px 72px;
  }

  .app-header h1 {
    font-size: 48px;
  }

  .about-heading {
    font-size: 48px;
    line-height: 56px;
  }

  .about-text {
    font-size: 18px;
    line-height: 28px;
  }

  .about-image {
    width: 100%;
    max-width: 400px;
    height: auto;
  }

  .section-container {
    margin-bottom: 80px;
  }

  .section-heading {
    font-size: 48px;
    line-height: 56px;
  }

  .section-sub-heading {
    font-size: 28px;
    line-height: 36px;
  }

  .card-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  .section-card {
    max-width: 100%;
  }

  .card-text h3 {
    font-size: 16px;
    line-height: 22px;
  }

  .card-text p {
    font-size: 12px;
    line-height: 18px;
  }

  .services-header h2 {
    font-size: 48px;
  }

  .services-header p {
    font-size: 24px;
  }

  .services-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  .services-card {
    padding: 24px;
  }

  .services-card h3 {
    font-size: 22px;
  }

  .services-card-text ul li {
    font-size: 14px;
  }
  .portfolio-container {
    padding: 0 6.75rem;
    gap: 2rem;
  }

  .portfolio-header {
    font-size: 3rem;
    line-height: 3rem;
  }

  .portfolio-btn-container {
    gap: 1rem;
  }

  .portfolio-btn-container button {
    width: 16rem;
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
  .portfolio-card-container {
    gap: 1rem;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .faq {
    flex-direction: column;
  }

  .faq-head {
    max-width: 100%;
    gap: 1rem;
  }

  .faq-heading {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .faq-button {
    width: 100%;
    max-width: 20rem;
  }

  .faq-card {
    padding: 1.5rem 2rem;
    width: 100%;
    max-width: none;
  }

  .faq-card-text {
    font-size: 1.25rem;
  }

  .faq-query p {
    font-size: 1rem;
  }

  .footer-body {
    align-items: flex-end;
  }

  .footer-heading {
    font-size: 2.5rem;
  }

  .footer-text p {
    width: 100%;
    font-size: 1.25rem;
  }

  .footer-logo {
    margin-top: 24px;
  }

  .footer-options {
    align-items: flex-end;
    justify-content: flex-start;
  }

  .footer-links,
  .footer-policies,
  .footer-socialmedia {
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  .middle-rule {
    height: 85%;
  }
}

@media (max-width: 878px) {
  .nav {
    display: none;
  }
  .menu{
    display: initial;
  }
  .footer {
    padding: 40px 24px;
  }
  .footer-head-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-heading {
    font-size: 2rem;
    line-height: 24px;
  }

  .footer-button {
    width: 100%;
    font-size: 1rem;
  }

  .footer-text p {
    width: 100%;
    font-size: 1.125rem;
  }

  .footer-logo {
    margin-bottom: 16px;
  }

  .footer-options {
    align-items: flex-start;
    margin: 16px 0px;
    gap: 8px;
  }

  .footer-socialmedia a {
    font-size: 1rem;
    padding: 8px 16px 8px 0px;
  }

  .footer-links,
  .footer-policies {
    width: 100%;
    justify-content: center;
  }
  .foot-last {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .nav-container {
    padding: 16px;
    height: 64px;
  }

  .quote-button {
    width: 100%;
  }

  .app-header {
    padding: 124px 54px 48px;
  }

  .app-header h1 {
    font-size: 32px;
  }

  .app-header p {
    font-size: 16px;
  }

  .app-header button {
    padding: 12px 24px;
    font-size: 14px;
  }

  .about-container {
    flex-direction: column;
    justify-content: center;
    margin: 0 6.75rem 60px;
    gap: 48px;
  }

  .about-heading {
    font-size: 40px;
    line-height: 48px;
  }

  .about-text {
    font-size: 16px;
    line-height: 24px;
  }

  .about-image {
    width: 100%;
    max-width: 320px;
    height: auto;
  }

  .section-container {
    margin: 0 16px 60px;
  }

  .section-heading {
    font-size: 40px;
    line-height: 48px;
  }

  .section-sub-heading {
    font-size: 24px;
    line-height: 32px;
  }

  .card-container {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .section-card {
    padding: 12px;
    max-width: 100%;
  }

  .card-text h3 {
    font-size: 14px;
    line-height: 20px;
  }

  .card-text p {
    font-size: 12px;
    line-height: 18px;
  }

  .services-header h2 {
    font-size: 36px;
  }

  .services-header p {
    font-size: 18px;
    font-size: 24px;
  }

  .services-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .services-card {
    padding: 16px;
  }

  .services-card h3 {
    font-size: 20px;
  }

  .services-card-text ul li {
    font-size: 12px;
  }

  .portfolio-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }

  .portfolio-header {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .portfolio-btn-container {
    flex-wrap: wrap;
  }

  .portfolio-btn-container button {
    width: 14rem;
    font-size: 0.9rem;
  }

  .portfolio-card-container {
    gap: 1.5rem;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .faq-heading {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .faq-text {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .faq-button {
    padding: 1rem;
    max-width: 100%;
    font-size: 1rem;
  }

  .faq-card {
    padding: 1rem;
  }

  .faq-card-text {
    font-size: 1.125rem;
  }

  .faq-query p {
    font-size: 0.875rem;
  }

  .swiperArrow {
    margin: 1.5rem 0;
    height: 4.4rem;
    width: 18.625rem;
    left: 50%;
  }
  .middle-rule {
    display: none;
  }
  .footer-body {
    flex-direction: column;
    align-items: center;
  }
  .footer-text {
    margin: 12px 0;
    text-align: center;
  }
  .footer-text p:nth-child(2) {
    font-size: 1rem;
    font-weight: 300;
  }
  .footer-logo {
    justify-content: center;
  }

  .modal-content {
    width: 90vw;
  }
}

@media (max-width: 480px) {
  .nav-container {
    padding: 12px;
  }

  .logo-icon {
    width: 60px;
    height: 60px;
  }

  .navword {
    font-size: 12px;
    padding: 6px 12px;
  }

  .quote-button {
    font-size: 14px;
    padding: 6px 18px;
  }

  .app-header {
    padding: 108px 36px 46px;
  }

  .app-header h1 {
    font-size: 24px;
  }

  .app-header p {
    font-size: 14px;
  }

  .app-header button {
    padding: 8px 16px;
    font-size: 12px;
  }

  .about-heading {
    font-size: 24px;
    line-height: 40px;
  }

  .about-text {
    font-size: 14px;
    line-height: 22px;
  }

  .about-image {
    max-width: 280px;
  }

  .section-heading {
    font-size: 24px;
    line-height: 40px;
  }

  .section-sub-heading {
    font-size: 20px;
    line-height: 28px;
  }

  .card-container {
    grid-template-columns: 1fr;
    gap: 16px;
  }
  .card-heading {
    font-size: 12px;
  }
  .card-about{
    display: none;
  }
  .section-card {
    padding: 8px;
    max-width: 100%;
  }

  .services-header h2 {
    font-size: 28px;
  }

  .services-header p {
    font-size: 14px;
    line-height: 24px;
  }

  .services-card {
    padding: 12px;
  }

  .services-card h3 {
    font-size: 18px;
  }

  .services-card-text ul li {
    font-size: 12px;
  }

  .portfolio-header {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .portfolio-btn-container button {
    width: 12rem;
    font-size: 0.8rem;
    padding: 0.6rem 1rem;
  }

  .portfolio-card-container {
    gap: 1rem;
  }

  .portfolio-card {
    width: 10rem;
    height: 15rem;
  }

  .portfolio-card:hover {
    width: 11rem;
  }

  .card-details {
    bottom: 16px;
    padding: 12px 16px;
    font-size: 0.8rem;
  }

  .card-link {
    top: 12px;
    right: 12px;
    padding: 8px;
    border-radius: 24px;
  }
  .card-link svg{
    height: 12px;
    width: 12px;
  }

  .faq-heading {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .faq-text {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .faq-button {
    padding: 0.8rem;
    font-size: 0.875rem;
  }

  .faq-card {
    padding: 1rem;
  }

  .faq-card-text {
    font-size: 1rem;
  }

  .faq-query p {
    font-size: 0.875rem;
  }

  .footer {
    padding: 32px 16px;
  }

  .footer-heading {
    font-size: 1.75rem;
  }

  .footer-button {
    width: 100%;
    font-size: 0.875rem;
    padding: 12px;
  }

  .footer-text p {
    width: 100%;
    font-size: 1rem;
  }

  .footer-logo {
    margin-bottom: 12px;
  }

  .footer-options {
    align-items: flex-start;
  }

  .footer-socialmedia a span {
    display: none;
  }

  .footer-links,
  .footer-policies {
    width: 100%;
  }
  .swiperArrow {
    margin: 1.5rem 0;
    height: 5rem;
    width: 20.625rem;
    left: 50%;
  }
  .modal-content {
    padding: 20px;
  }

  .sendButton {
    font-size: 14px;
    padding: 10px 20px;
  }

  .full-name {
    flex-wrap: wrap;
  }

  .modal-content h2 {
    font-size: 18px;
  }
}

/* .text {
  font-size: clamp(12px, 5vw, 24px);  
} */

