.About {
  margin-top: 1px;
  background-color: var(--bg-week, #f9fafb);
}

.welcome {
  margin: 0 auto;
  padding: 196px 108px 108px;
  text-align: center;
  background-color: var(--bg-week, #f9fafb);
}

.about-greet {
  display: flex;
  justify-content: center;
  justify-self: center;
  width: 400px;
  color: var(--text-sub, #475467);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.4rem;
  margin-bottom: 1rem;
  position: relative;
}

.about-tagline {
  color: var(--text-strong, #0c111d);
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 3.2rem;
  margin: 1.5rem 0;
}

.about-tagline span:nth-child(1) {
  color: #63c5ea;
}

.about-tagline span:nth-child(3) {
  color: #ea4b8b;
}

.about-greet-text {
  color: var(--text-soft, #98a2b3);
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 1rem;
}

.about-arrow,
.element-eleven {
  position: absolute;
  height: auto;
}

.about-arrow {
  top: 108px;
  left: -272px;
}

.element-eleven {
  right: -280px;
  top: 138px;
}

.in-svg {
  width: 80%;
  height: 80%;
}

.int-svg {
  width: 62%;
  height: 100%;
}

@media (max-width: 1024px) {
  .welcome {
    padding: 174px 72px 72px; 
  }

  .about-greet {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  .about-tagline {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }

  .about-greet-text {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }

  .about-arrow {
    left: -198px;
  }

  .element-eleven {
    top: 123px;
  }

  .in-svg {
    width: 62%;
  }

  .int-svg {
    width: 56%;
  }
}

@media (max-width: 768px) {
  .welcome {
    padding: 120px 54px 36px;
  }

  .about-greet {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .about-tagline {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }

  .about-greet-text {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }
  .about-arrow {
    left: -46px;
    top: 84px;
  }

  .element-eleven {
    top: 92px;
    right: -248px;
  }

  .in-svg {
    width: 38%;
  }

  .int-svg {
    width: 34%;
  }
}

@media (max-width: 480px) {
  .welcome {
    padding: 96px 48px 32px;
  }

  .about-greet {
    font-size: 1rem;
    line-height: 1.8rem;
  }

  .about-tagline {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .about-greet-text {
    font-size: 0.7rem;
    line-height: 1.2rem;
  }
  .about-arrow {
    left: 32px;
    top: 72px;
  }

  .element-eleven {
    top: 72px;
    right: -244px;
  }

  .in-svg {
    width: 22%;
  }

  .int-svg {
    width: 26%;
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .welcome {
    padding: 196px 108px 196px;
  }

  .about-greet {
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .about-tagline {
    font-size: 3rem;
    line-height: 3.8rem;
  }

  .about-greet-text {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .about-arrow {
    top: 152px;
    left: -408px;
  }

  .element-eleven {
    right: -302px;
    top: 180px;
  }

  .in-svg {
    width: 100%;
    height: 100%;
  }

  .int-svg {
    width: 90%;
    height: 100%;
  }
}

/* =============================================== */

.about-sec {
  display: flex;
  padding: 2vw;
  margin: 24px;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  border-radius: 40px;
  border: 1px solid var(--storke-soft, #eaecf0);
  background: var(--bg-white, #fff);
}

.about-sec-text {
  flex: 1 1 60%;
  max-width: 680px;
  padding-left: 60px;
}

.about-sec-text div {
  color: var(--text-strong, #0c111d);
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3rem;
}

.about-sec-text div span:nth-child(1) {
  color: #63c5ea;
}

.about-sec-text div span:nth-child(2) {
  color: #ea4b8b;
}

.about-sec-text p {
  padding: 8px 0;
  width: 90%;
  text-align: justify;
  color: var(--text-soft, #475467);
  font-size: 1rem;
  line-height: 1.5rem;
}

@supports (aspect-ratio: 1) {
  .about-sec-image {
    flex: 1 1 40%;
    max-width: 556px;
    aspect-ratio: 7 / 8;
    border-radius: 24px;
    background-size: cover;
    background-position: center;
    background: url("../images/image.jpg") lightgray 0px 0px / 100% 100%
      no-repeat;
  }
}

@media (max-width: 1024px) {

  .about-sec-text {
    width: 100%;
    max-width: none;
    padding-left: 24px;
  }

  .about-sec-text div {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .about-sec-text p {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .about-sec-text {
    padding: 2vw;
  }
  .about-sec {
    padding: 4vw;
  }

  .about-sec-text div {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .about-sec-text p {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }

  @supports (aspect-ratio: 1) {
    .about-sec-image {
      aspect-ratio: 4/7;
    }
  }
}

@media (max-width: 480px) {
  .about-sec-text div {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .about-sec-text p {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }

  .about-sec {
    flex-direction: column;
    gap: 0px;
  }

  @supports (aspect-ratio: 1) {
    .about-sec-image {
      width: 100%;
      aspect-ratio: 1;
    }
  }
}

/* ============================================== */

.our-team {
  display: flex;
  align-items: stretch;
  gap: 24px;
  margin: 24px;
}

.our-team-container,
.our-team-image {
  flex: 1 1 50%;
  padding: 2vw;
  border-radius: 40px;
  border: 1px solid var(--storke-soft, #eaecf0);
  background: var(--bg-white, #fff);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.our-team-container {
  gap: 24px;
  padding: 4vw;
}

.our-team-head h2 {
  color: var(--text-strong, #0c111d);
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3rem;
}

.our-team-head p {
  color: var(--text-soft, #98a2b3);
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
}

.our-team-detail h3 {
  color: var(--text-strong, #0c111d);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.4rem;
  margin-bottom: 1vw;
}

.our-team-detail p {
  color: var(--text-sub, #475467);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: justify;
}

.our-team-detail button {
  margin-top: 12px;
  padding: 12px 24px;
  border-radius: 32px;
  border: 1px solid var(--primary-light, #cac0ff);
  background: var(--primary-darker, #7d52f4);
  color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
  align-self: start;
}

.our-team-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@supports (aspect-ratio: 1) {
  .member-image {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/0.9;
    border-radius: 24px;
    background: url("../images/Rectangle 3.jpg") lightgray 50% / cover no-repeat;
  }
}

@media (max-width: 1024px) {
  .our-team-head h2 {
    font-size: 2rem;
  }

  .our-team-head p {
    font-size: 1rem;
  }

  .our-team-detail h3 {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .our-team {
    flex-direction: column;
  }

  .our-team-head h2 {
    font-size: 1.8rem;
  }

  .our-team-detail h3 {
    font-size: 1.4rem;
  }
}

@media (max-width: 480px) {
  .our-team-head h2 {
    font-size: 1.5rem;
  }

  .our-team-detail h3 {
    font-size: 1.2rem;
  }
  .our-team-image {
    padding: 4vw;
  }
}

.last-about {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 24px 28px;
  background: var(--bg-week, #f9fafb);
  border-radius: 24px;
}

.last-about-text {
  width: 80%;
  color: var(--text-strong, #0c111d);
  font-size: 3rem;
  font-weight: 500;
  line-height: 3.2rem;
  margin: 0vh 4vw 5vw;
}

.last-about-text span:nth-child(1) {
  color: #7d52f4;
}

.last-about-text span:nth-child(2) {
  color: #63c5ea;
}

.last-about-text span:nth-child(3) {
  color: #ea4b8b;
}

.last-about-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 24px;
}

.last-about-card {
  flex: 1 1 40%;
  padding: 6vw 4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  border-radius: 40px;
  border: 1px solid var(--storke-soft, #eaecf0);
  background: var(--bg-white, #fff);
  text-align: left;
}

.last-about-card-head {
  color: var(--text-strong, #0c111d);
  font-size: 2rem;
  line-height: 2.8rem;
}

.last-about-card p {
  color: var(--text-soft, #98a2b3);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
}

.welcome-head {
  color: var(--text-strong, #0c111d);
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.4rem;
}

.welcome-note {
  color: var(--text-sub, #475467);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
}

.last-about-card button {
  width: 100%;
  max-width: 200px;
  padding: 12px;
  border-radius: 32px;
  border: 1px solid #7d52f4;
  background-color: #7d52f4;
  color: #ffffff;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}

.last-about-card button:hover {
  background-color: #6345c7;
}

.email input {
  width: 100%;
  max-width: 500px;
  padding: 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 400;
}

.email label {
  padding: 8px 0px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--text-strong);
}

@media (max-width: 768px) {
  .last-about-text {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  .last-about-card {
    flex: 1 1 100%;
    padding: 5vh 2vw;
  }

  .last-about-card-head {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  .last-about-card p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  .last-about-card button {
    padding: 10px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .last-about-text {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .last-about-card-head {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .last-about-card p {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .email input {
    padding: 10px;
    font-size: 0.9rem;
  }

  .email label {
    font-size: 0.9rem;
  }
}
